import React from 'react';
import {
    SIGNIN_ROUTE,
    HOME_ROUTE,
    MY_STUDENTS_ROUTE,
    SETTINGS_ROUTE,
    EDIT_GROUP_ROUTE,
    ADD_GROUP_ROUTE,
    HISTORY_ROUTE,
    LESSONS_ROUTE,
    ADD_LESSON_ROUTE,
    LESSON_ROUTE,
    MY_STUDENT_ROUTE,
    EDIT_LESSON_ROUTE,
    PAYMENTS_ROUTE,
    PAY_ROUTE,
    SIGNUP_ROUTE,
    ACCESS_ROUTE,
    TEACHERS_ROUTE,
    ADD_TEACHER_ROUTE,
    STUDENTS_ROUTE,
    EDIT_STUDENT_ROUTE,
    STUDENT_ROUTE,
    GROUPS_ROUTE,
    EDIT_TEACHER_ROUTE,
    BIRTHDAYS_ROUTE,
    HOMEWORKS_ROUTE,
    WAGES_ROUTE,
    ADD_PREMIUM_ROUTE,
    ANALYTICS_ROUTE,
    ADD_PERCENT_ROUTE,
    COPY_LESSON_ROUTE
} from "./utils";
import Signin from "./page/authPage";
import Signup from './page/registerPage';
import HomePage from './page/homePage';
import MyStudentsPage from './page/myStudentsPage';
import SettingsPage from './page/settingsPage';
import EditGroupPage from './page/editGroupePage';
import AddGroupPage from './page/addGroupePage';
import HistoryPage from './page/historyPage';
import LessonsPage from './page/lessonsPage';
import AddLessonPage from './page/addLessonPage';
import LessonPage from './page/lessonPage';
import MyStudentPage from './page/myStudentPage';
import EditLessonPage from './page/editLessonPage';
import PaymentsPage from './page/paymentsPage';
import PaymentPage from './page/paymentPage';
import AccessPage from './page/accessPage'
import TeachersPage from "./page/teachersPage";
import AddTeacherPage from "./page/addTeacherPage";
import StudentsPage from "./page/studentsPage";
import EditStudentPage from "./page/editStudentPage";
import StudentPage from "./page/studentPage";
import GroupsPage from "./page/groupsPage";
import EditTeacherPage from "./page/editTeacherPage";
import BirthdaysPage from "./page/birthdaysPage";
import HomeworksPage from "./page/homeworksPage";
import WagesPage from "./page/wagesPage";
import AddPremiumPage from "./page/addPremiumPage";
import AnalyticsPage from "./page/analyticsPage";
import AddPercentPage from "./page/addPercentPage";
import CopyLessonPage from "./page/CopyLessonPage";

export const routes = [
    {
        path: SIGNIN_ROUTE,
        Component: <Signin/>
    },
    {
        path: SIGNUP_ROUTE,
        Component: <Signup/>
    },
    {
        path: HOME_ROUTE,
        Component: <HomePage/>
    },
    {
        path: MY_STUDENTS_ROUTE + '/:id',
        Component: <MyStudentsPage/>
    },
    {
        path: SETTINGS_ROUTE,
        Component: <SettingsPage/>
    },
    {
        path: EDIT_GROUP_ROUTE + '/:id',
        Component: <EditGroupPage/>
    },
    {
        path: ADD_GROUP_ROUTE,
        Component: <AddGroupPage/>
    },
    {
        path: HISTORY_ROUTE + '/:id',
        Component: <HistoryPage/>
    },
    {
        path: LESSONS_ROUTE + '/:id',
        Component: <LessonsPage/>
    },
    {
        path: ADD_LESSON_ROUTE + '/:id',
        Component: <AddLessonPage/>
    },
    {
        path: LESSON_ROUTE + '/:id/:number',
        Component: <LessonPage/>
    },
    {
        path: MY_STUDENT_ROUTE + '/:groupId/:id',
        Component: <MyStudentPage/>
    },
    {
        path: EDIT_LESSON_ROUTE + '/:id/:number',
        Component: <EditLessonPage/>
    },
    {
        path: COPY_LESSON_ROUTE + '/:id/:number',
        Component: <CopyLessonPage/>
    },
    {
        path: HOMEWORKS_ROUTE + '/:id',
        Component: <HomeworksPage/>
    },
    {
        path: PAYMENTS_ROUTE + '/:id',
        Component: <PaymentsPage/>
    },
    {
        path: PAY_ROUTE + '/:id',
        Component: <PaymentPage/>
    },
    {
        path: ACCESS_ROUTE + '/:id',
        Component: <AccessPage/>
    },
    {
        path: TEACHERS_ROUTE,
        Component: <TeachersPage/>
    },
    {
        path: ADD_TEACHER_ROUTE,
        Component: <AddTeacherPage/>
    },
    {
        path: STUDENTS_ROUTE,
        Component: <StudentsPage/>
    },
    {
        path: STUDENT_ROUTE + '/:id',
        Component: <StudentPage/>
    },
    {
        path: EDIT_STUDENT_ROUTE + '/:id',
        Component: <EditStudentPage/>
    },
    {
        path: EDIT_TEACHER_ROUTE + '/:id',
        Component: <EditTeacherPage/>
    },
    {
        path: GROUPS_ROUTE,
        Component: <GroupsPage/>
    },
    {
        path: BIRTHDAYS_ROUTE,
        Component: <BirthdaysPage/>
    },
    {
        path: WAGES_ROUTE,
        Component: <WagesPage/>
    },
    {
        path: ADD_PREMIUM_ROUTE,
        Component: <AddPremiumPage/>
    },
    {
        path: ANALYTICS_ROUTE,
        Component: <AnalyticsPage/>
    },
    {
        path: ADD_PERCENT_ROUTE,
        Component: <AddPercentPage/>
    }
]