import React, {useState, useEffect} from 'react'
import Footer from '../components/footerComponent/footer'
import CopyLesson from '../components/teacherComponent/copyLessonComponent'
import Header from '../components/headerComponent/header'
import {Layout} from 'antd';
import {useNavigate} from 'react-router-dom';
import {SIGNIN_ROUTE} from '../utils';
import {isTokenValid} from '../utils/authUtils';

export default function CopyLessonPage() {

    const navigate = useNavigate();
    let [isAuth, setAuth] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token != null && isTokenValid(token)) {
            setAuth(true);
        } else {
            localStorage.removeItem("token");
            setAuth(false);
            navigate(SIGNIN_ROUTE)
        }
    }, [navigate]);

    return (
        isAuth
            ?
            <Layout>
                <Header/>
                <CopyLesson/>
                <Footer/>
            </Layout>
            :
            <p/>
    );
};