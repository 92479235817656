import React, {useState, useEffect} from 'react'
import {getNameById} from "../../http/GroupApi";
import {getLessonsByGroupId, getAllByGroupId} from "../../http/StudentLessonApi";
import {Button, Table, Spin, Divider, ConfigProvider, Empty} from 'antd';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {LeftOutlined, VideoCameraOutlined, BookOutlined, StarOutlined, CopyOutlined} from '@ant-design/icons';
import {MY_STUDENT_ROUTE, LESSON_ROUTE, ADD_LESSON_ROUTE, EDIT_LESSON_ROUTE, COPY_LESSON_ROUTE} from '../../utils';
import dayjs from 'dayjs';

export default function Lessons() {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const params = useParams();

    const [groupName, setGroupName] = useState('');
    const [lessonsColumns, setLessonsColumns] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(true);

    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const dateFormat = 'DD.MM.YYYY';

    useEffect(() => {
        getName();
        getHistoryTitles();
    }, []);

    const getName = async () => {
        await getNameById(localStorage.getItem('token'), params.id).then(
            response => {
                setGroupName(response.data);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getHistoryTitles = async () => {
        await getLessonsByGroupId(localStorage.getItem('token'), params.id).then(
            response => {
                const lessonsTitles = [
                    {
                        title: 'Посещаемость',
                        dataIndex: 'student',
                        key: 'student',
                    },
                ];
                response.data.map((row, index) => {
                    let linkPath = index === 0 ? `${EDIT_LESSON_ROUTE}/${params.id}/${row.lessonNumber}` : `${LESSON_ROUTE}/${params.id}/${row.lessonNumber}`;
                    lessonsTitles.push({
                        title: <div>
                            <Link to={linkPath} className="custom-link">
                                {`Ур № ${row.lessonNumber} ${dayjs(row.date).format(dateFormat)} - ${daysOfWeek[dayjs(row.date).day()]}.`}
                            </Link>
                            <Button onClick={() => navigate(`${COPY_LESSON_ROUTE}/${params.id}/${row.lessonNumber}`)} style={{
                                marginLeft: '1vw',
                                background: 'transparent'
                            }}><CopyOutlined/></Button>
                        </div>,
                        dataIndex: row.lessonNumber,
                        key: row.lessonNumber,
                    });
                })
                setLessonsColumns(
                    lessonsTitles
                );
                getHistory()
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    const getHistory = async () => {
        await getAllByGroupId(localStorage.getItem('token'), params.id).then(
            response => {
                const lessonsData = []
                response.data.forEach(element => {
                    const lessonObject = {
                        student: <Link to={`${MY_STUDENT_ROUTE}/${params.id}/${element.student.id}`}
                                       className="custom-link">{element.student.name} ({element.balance}₽
                            + {element.bonusBalance}₽ бонусов)</Link>
                    }
                    element.studentLessons.forEach(
                        lesson => {
                            lessonObject[lesson.lessonNumber] = <>
                                {lesson.paymentStatus === 'ATTEND_LESSON' &&
                                    <><BookOutlined style={{color: '#04879C'}}/> ({lesson.cost}₽)</>
                                }
                                {lesson.paymentStatus === 'WATCH_VIDEO' &&
                                    <><VideoCameraOutlined style={{color: '#F30A49'}}/> ({lesson.cost}₽)</>
                                }
                                {(lesson.paymentStatus === 'OWN_PRICE' ||
                                        lesson.paymentStatus === 'OWN_PRICE2' ||
                                        lesson.paymentStatus === 'OWN_PRICE3' ||
                                        lesson.paymentStatus === 'OWN_PRICE4') &&
                                    <>
                                        <div className="star-container" style={{color: '#0C3C78'}}>
                                            <StarOutlined className="star-icon"/>
                                            <span className="number-overlay">
                                                <>
                                                   {lesson.paymentStatus === 'OWN_PRICE' && <>1</>}
                                                    {lesson.paymentStatus === 'OWN_PRICE2' && <>2</>}
                                                    {lesson.paymentStatus === 'OWN_PRICE3' && <>3</>}
                                                    {lesson.paymentStatus === 'OWN_PRICE4' && <>4</>}
                                                </>
                                            </span>
                                        </div>
                                        ({lesson.cost}₽)
                                    </>
                                }
                            </>
                        }
                    )
                    lessonsData.push(lessonObject)
                });
                setLessons(
                    lessonsData
                )
                setLoading(false);
            }).catch(
            error => {
                console.log(error)
            }
        );
    };

    return (
        <div
            style={{
                minHeight: '100vh',
            }}
        >
            <Button onClick={goBack} style={{
                margin: '1vw',
            }}><LeftOutlined/> Назад</Button>
            <Divider>Группа: {groupName}</Divider>

            <Button onClick={() => navigate(`${ADD_LESSON_ROUTE}/${params.id}`)} style={{
                margin: '1vw',
                display: 'block'
            }}>Добавить занятие</Button>
            <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет занятий'/>}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Spin/>
                    </div>
                ) : (
                    <Table
                        columns={lessonsColumns}
                        dataSource={lessons}
                        pagination={false}
                        bordered
                        scroll={{x: "max-content"}}
                        style={{
                            margin: '1vw',
                        }}
                    />
                )}
            </ConfigProvider>
        </div>
    );
}